
















































































































import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import Uploader from "@/components/settings/upload/Uploader.vue";
import Profile from "@/interfaces/Profile";
import { pick } from "lodash";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "EditProfile",
  components: {
    Uploader,
    ProfilePhoto,
  },
  setup: () => {
    const vm = reactive({
      loading: false
    });

    const partialProfile: Partial<Profile> = pick(userModule.state.profile, ["aboutText", "interestsText", "previousWorkText", "tagline", "tutorText", "uid"]);
    const photoURL = computed(() => userModule.state.profile.photoURL);
    const notify = useNotifier();
    const updateProfile = async(newPhotoURL: string) => {
      await userModule.actions.updateUserProfile({ photoURL: newPhotoURL });
    };

    async function saveProfile() {
      vm.loading = true;

      try {
        await userModule.actions.updateUserProfile({
          aboutText: partialProfile.aboutText,
          interestsText: partialProfile.interestsText,
          previousWorkText: partialProfile.previousWorkText,
          tagline: partialProfile.tagline,
          tutorText: partialProfile.tutorText,
          uid: partialProfile.uid,
        });

        notify({
          title: "Informasjonen ble lagret",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      partialProfile,
      photoURL,
      saveProfile,
      updateProfile,
      vm
    };
  },
});
