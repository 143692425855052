import learnlinkAPI from "./learnlinkAPI";

interface AuthObject {
  email: string;
  password: string;
  uid: string;
}

export const updateAuthObject = async(authObject: AuthObject): Promise<AuthObject> => {
  const authRes = await learnlinkAPI.axios.put<AuthObject>(`/auth/${authObject.uid}`, authObject);
  return authRes.data;
};

export const doesEmailExist = async(email: string): Promise<boolean> => {
  const authRes = await learnlinkAPI.axios.get<boolean>(
    `/auth/exists/${email}`,
  );
  return authRes.data;
};

export const deleteUser = async(uid: string): Promise<AuthObject> => {
  const authRes = await learnlinkAPI.axios.delete<AuthObject>(
    `/auth/${uid}`,
  );
  return authRes.data;
};
