import learnlinkAPI from "./learnlinkAPI";

export interface NotificationSetting {
  ID: string;
  customer: boolean;
  description: string;
  emailActive: boolean;
  title: string;
  seller: boolean;
  smsActive: boolean;
}

export const listNotifications = async(): Promise<NotificationSetting[]> => {
  const notificationRes = await learnlinkAPI.axios.get<NotificationSetting[]>("/notifications/getAllNotificationTypes");
  return notificationRes.data;
};

export const updateNotificationSetting = async(notificationType: string, settingToggled: "email" | "sms", newValue: boolean, uid: string): Promise<void> => {
  const notificationRes = await learnlinkAPI.axios.put(`/accounts/${uid}/notificationSettings/${notificationType}/${settingToggled}/${newValue}`);
  return notificationRes.data;
};
