











































import AddCard from "./AddCard.vue";
import { getStripeCards } from "@/api/stripeService";
import useAsyncData from "@/hooks/useAsyncData";
import { userModule } from "@/store/modules/user";
import StripeCardTemplate from "./StripeCardTemplate.vue";
import { defineComponent } from "@vue/composition-api";
import { StripeCard } from "@/interfaces/Stripe";

export default defineComponent({
  name: "ExistingCards",
  components: { AddCard, StripeCardTemplate },
  setup: () => {
    const { isLoading, data: stripeCardView, hasError } = useAsyncData(() => getStripeCards(userModule.state.userId));

    const handleDelete = (deletedCardID: string) => {
      if (stripeCardView.value) {
        const allCards = stripeCardView.value.cards;
        stripeCardView.value.cards = allCards.filter(card => card.id !== deletedCardID);
      }
    };

    const handleNewCard = (newCard: StripeCard) => {
      if (stripeCardView.value) {
        stripeCardView.value.cards.push(newCard);
      }
    };

    const handleUpdate = (cardID: string) => {
      if (stripeCardView.value) {
        stripeCardView.value.default_source = cardID;
      }
    };

    return {
      stripeCardView,
      defaultCard: (cardID: string) => {
        if (stripeCardView.value) return stripeCardView.value.default_source === cardID;
        return false;
      },
      handleDelete,
      handleNewCard,
      handleUpdate,
      isLoading,
      hasError,
    };
  },
});

