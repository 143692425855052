









import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ProfilePhoto",
  props: {
    url: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const profilePhotoPath = computed(() => {
      if (props.url !== "statics/nophoto.jpg" && props.url !== undefined) {
        if (!props.url.includes("https://firebasestorage") &&
          !props.url.includes("https://storage.googleapis.com")) {
          return "https://firebasestorage.googleapis.com/v0/b/learnlink-prod.appspot.com/o/public%2Fnophoto.jpg?alt=media&token=89b86054-8637-46ed-8aa8-e54cf68d4779";
        }
        return props.url;
      }
      return "https://firebasestorage.googleapis.com/v0/b/learnlink-prod.appspot.com/o/public%2Fnophoto.jpg?alt=media&token=89b86054-8637-46ed-8aa8-e54cf68d4779";
    });

    return {
      profilePhotoPath
    };
  },
});
