




















import { listNotifications, NotificationSetting } from "@/api/notificationService";
import NotificationSettingView from "./NotificationSettingView.vue";
import useAsyncData from "@/hooks/useAsyncData";
import { defineComponent } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "Notifications",
  components: { NotificationSettingView },
  setup: () => {
    const { isLoading, data: notificationTypes } = useAsyncData(() => listNotifications());
    const isSeller = userModule.state.user.seller;

    const showNotificationType = (notificationType: NotificationSetting): boolean => {
      return (notificationType.seller && isSeller) || (notificationType.customer && !isSeller);
    };

    return {
      isLoading,
      notificationTypes,
      showNotificationType,
    };
  },
});

