export type Gender = "" | "male" | "female" | "other";

export enum Genders {
  male = "Mann",
  female = "Kvinne",
  other = "Annet",
}

export function mapGender(gender: keyof typeof Genders): string {
  return Genders[gender];
}
