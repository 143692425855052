



























import Uploader from "./Uploader.vue";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "UploadDocumentation",
  components: { Uploader },
  setup: () => {
    const tab = ref("");
    const items = ref([
      {
        title: "Legitimasjon",
        documentType: "identifications",
      },
      {
        title: "Politiattest",
        documentType: "policeRecords",
      },
      {
        title: "Vitnemål",
        documentType: "certificates",
      },
    ]);

    return {
      tab,
      items,
    };
  },
});
