




































import { NotificationSetting, updateNotificationSetting } from "@/api/notificationService";
import { useNotifier } from "@/providers/notifier";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "NotificationSettingView",
  props: {
    notificationType: {
      type: Object as PropType<NotificationSetting>,
      required: true,
    },
  },
  setup: (props: { notificationType: NotificationSetting }) => {
    const emailSetting = userModule.state.account.notificationSettings[props.notificationType.ID].email ? userModule.state.account.notificationSettings[props.notificationType.ID].email : false;
    const smsSetting = userModule.state.account.notificationSettings[props.notificationType.ID].sms ? userModule.state.account.notificationSettings[props.notificationType.ID].sms : false;

    const email = ref(emailSetting);
    const sms = ref(smsSetting);
    const notify = useNotifier();

    const updateSetting = async(settingToggled: "email" | "sms") => {
      const newValue = settingToggled === "email" ? email.value : sms.value;
      try {
        await updateNotificationSetting(props.notificationType.ID, settingToggled, newValue, userModule.state.userId);
        notify({
          title: "Varsling oppdatert",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
    };

    return {
      email,
      sms,
      updateSetting,
    };
  },
});

