























































import { uploadProfilePhoto } from "@/api/userService";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { DocumentType, listFiles, uploadFiles } from "@/api/fileService";
import FileTemplate from "./FileTemplate.vue";
import useAsyncData from "@/hooks/useAsyncData";
import { useNotifier } from "@/providers/notifier";

interface HTMLInputEvent extends Event {
  dataTransfer: HTMLInputElement & DataTransfer;
  target: HTMLInputElement & EventTarget;
}

export default defineComponent({
  name: "Uploader",
  components: { FileTemplate },
  props: {
    documentType: {
      type: String as PropType<DocumentType>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const isUploading = ref(false);
    const {
      isLoading,
      data: uploadedFiles,
    } = useAsyncData(() => listFiles(props.documentType, userModule.state.userId));
    const notify = useNotifier();
    const fileInputInformation = computed(() => {
      return "Dra fil hit eller <em>klikk for å laste opp.</em> Vi godtar kun bildefiler.<br><b>Bildet må være tydelig," +
        " i farger, og rotert riktig vei.</b><br>" + (props.documentType === "identifications"
        ? "<b>NB! Eneste gyldige legitimasjon er pass.<br></b>"
        : "");
    });
    const divHeight = computed(() => {
      return props.documentType === "identifications" ? "height: 150px" : "height: 120px";
    });

    const handleFileInput = (e: HTMLInputEvent) => {
      const fieldName = e.target.name;
      const fileList = e.target.files;
      const formData = new FormData();

      if (!fileList || !fileList.length) return;

      Array
        .from(Array(fileList.length).keys())
        .forEach(x => {
          formData.append(fieldName, fileList[x], fileList[x].name);
        });

      save(formData);
    };

    const handleDelete = (deletedFileID: string) => {
      if (uploadedFiles.value) {
        const allFiles = uploadedFiles.value;
        uploadedFiles.value = allFiles.filter((file) => file.uid !== deletedFileID);
      }
    };

    const save = async(formData: FormData) => {
      try {
        isUploading.value = true;
        const fileRes = props.documentType === "profilePhoto"
          ? await uploadProfilePhoto(userModule.state.userId, formData)
          : await uploadFiles(formData, props.documentType, userModule.state.userId);
        if (uploadedFiles.value && typeof fileRes !== "string") {
          uploadedFiles.value = uploadedFiles.value.concat(fileRes);
        }
        if (props.documentType === "profilePhoto") emit("uploadComplete", fileRes);
        notify({
          title: fileRes.length > 1 ? "Filene ble lastet opp!" : "Filen ble lastet opp!",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        if (e.response.status === 413) {
          notify({
            title: "💥",
            message: "Filen var så stor at den sprengte. Prøv en mindre fil.",
            type: "error",
          });
        }
        else if (e.response.status === 400) {
          notify({
            title: "🔍",
            message: "Vi klarte ikke å lese informasjonen på bildet. Sørg for at teksten er tydelig og at " +
              "bildet er skarpt. Prøv igjen hvis problemet vedvarer.",
            type: "error",
          });
        }
        else if (e.response.status === 406) {
          notify({
            title: "🔍",
            message: "Informasjonen på bildet stemmer ikke overens med informasjonen på din profil. Det kan komme av " +
              "skrivefeil, utydelig bilde, eller intern feil hos oss. Dobbeltsjekk at informasjonen på bildet er tydelig " +
              "og at profilinformasjonen stemmer overens med informasjonen på bildet. Ta kontakt med support dersom problemet vedvarer.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Oops",
            message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
            type: "error",
          });
        }
      }
      uploadedFiles.value = await listFiles(props.documentType, userModule.state.userId);
      isUploading.value = false;
    };

    return {
      divHeight,
      fileInputInformation,
      isLoading,
      handleDelete,
      handleFileInput,
      isUploading,
      uploadedFiles,
    };
  },
});

