import learnlinkAPI from "./learnlinkAPI";

export type DocumentType = "certificates" | "identifications" | "policeRecords" | "profilePhoto";

export interface FileReference {
  approved: number;
  created: number;
  deleted: number;
  name: string;
  OCR: string;
  rejected: number;
  uid: string;
  url: string;
}

export const listFiles = async(collection: DocumentType, uid: string): Promise<FileReference[]> => {
  const fileRes = await learnlinkAPI.axios.get(`/sellers/files/${collection}/${uid}`);
  return fileRes.data;
};

export const deleteFile = async(collection: DocumentType, fileID: string, uid: string): Promise<void> => {
  const deleteRes = await learnlinkAPI.axios.delete(`/sellers/files/deleteFile/${collection}/${uid}/${fileID}`);
  return deleteRes.data;
};

export const uploadFiles = async(formData: FormData, collection: DocumentType, uid: string): Promise<FileReference[]> => {
  const uploadRes = await learnlinkAPI.axios.post(`/sellers/files/upload/${collection}/${uid}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return uploadRes.data;
};
