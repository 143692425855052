


































































































































































































import { pick } from "lodash";
import { useNotifier } from "@/providers/notifier";
import { UserSettingsObject } from "@/views/Settings.vue";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, reactive, ref, watch } from "@vue/composition-api";
import { Gender, mapGender } from "@/helpers/genderUtils";
import { syncStripeAccounts } from "@/api/stripeService";
import handleError from "@/helpers/errors";
import formRules from "@/helpers/formRules";

interface AboutMeObject {
  account: {
    address: string;
    birthdate: string;
    city: string;
    personNumber: string;
    phone: string;
    uid: string;
  };
  profile: {
    firstName: string;
    lastName: string;
    uid: string;
  },
  user: {
    betaTester: boolean;
    gender: Gender;
    postalCode: string;
    seller: boolean;
    uid: string;
  };
}

const isAddressDisabled = (requirements: { currently_due: string[]; }) => {
  return requirements &&
    (!requirements.currently_due.length && (
      !requirements.currently_due.includes("individual.address.city") ||
      !requirements.currently_due.includes("individual.address.line1") ||
      !requirements.currently_due.includes("individual.address.postal_code")));
};

export default defineComponent({
  name: "AboutMe",
  setup: () => {
    const vm = reactive({
      loading: false
    });

    const settingsObject = ref<UserSettingsObject>({
      account: userModule.state.account,
      profile: userModule.state.profile,
      stripeManagedAccount: {
        payouts_enabled: true,
        requirements: {
          currently_due: [
            "individual.address.city",
          ],
        },
      },
      user: userModule.state.user,
    });

    const partialAccount = pick(userModule.state.account, ["address", "birthdate", "city", "personNumber", "phone", "uid"]);
    const partialProfile = pick(userModule.state.profile, ["firstName", "lastName", "uid"]);
    const partialUser = pick(userModule.state.user, ["betaTester", "gender", "postalCode", "seller", "uid"]);
    const form = ref();
    const menu = ref(false);
    const picker = ref<{ activePicker: string; } | null>(null);
    const updateObject = ref<AboutMeObject>({
      account: partialAccount,
      profile: partialProfile,
      user: partialUser,
    });
    const notify = useNotifier();
    const validForm = ref(false);
    const genders = ref([
      {
        text: mapGender("male"),
        value: "male",
      },
      {
        text: mapGender("female"),
        value: "female",
      },
      {
        text: mapGender("other"),
        value: "other",
      },
    ]);

    async function saveUserInfo() {
      vm.loading = true;

      try {
        form.value.validate();

        if (validForm.value) {
          await Promise.all([
            userModule.actions.updateUserAccount(
              updateObject.value.account,
            ),
            userModule.actions.updateUserProfile({
              firstName: updateObject.value.profile.firstName,
              lastName: updateObject.value.profile.lastName,
              uid: updateObject.value.profile.uid,
            }),
            userModule.actions.updateUserObject({
              betaTester: updateObject.value.user.betaTester,
              gender: updateObject.value.user.gender,
              postalCode: updateObject.value.user.postalCode,
              uid: updateObject.value.user.uid,
            }),
            syncStripeAccounts(updateObject.value.user.uid)
          ]);

          notify({
            title: "Informasjonen ble lagret",
            message: "",
            type: "success",
          });
        }
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }

      vm.loading = false;
    }

    watch(menu, (val) => {
      // FIXME: Picker.value === null on first click
      if (val && picker.value !== null) {
        picker.value.activePicker = "YEAR";
      }
    });

    return {
      disableAddress: computed(() => isAddressDisabled(settingsObject.value.stripeManagedAccount.requirements)),
      form,
      formRules,
      genders,
      menu,
      picker,
      saveUserInfo,
      updateObject,
      validForm,
      vm,
    };
  },
});
