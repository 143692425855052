import {
  ConnecteAccountRequirementMessage,
  StripeBankAccount,
  StripeBankAccountView,
  StripeCard,
  StripeCardView,
} from "@/interfaces/Stripe";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/stripe";

export const createExternalAccount = async(uid: string, tokenID: string): Promise<StripeBankAccount> => {
  const stripeRes = await learnlinkAPI.axios.post(`${ENDPOINT}/connect/addBankAccount/${uid}`, { tokenID });
  return stripeRes.data;
};

export const createStripeCard = async(uid: string, tokenID: string): Promise<StripeCard> => {
  const stripeRes = await learnlinkAPI.axios.post(`${ENDPOINT}/customers/addCard/${uid}`, { tokenID });
  return stripeRes.data;
};

export const getConnectedAccount = async(uid: string): Promise<ConnecteAccountRequirementMessage> => {
  const stripeRes = await learnlinkAPI.axios.get<ConnecteAccountRequirementMessage>(`${ENDPOINT}/requirements/${uid}`);
  return stripeRes.data;
};

export const getStripeAccounts = async(uid: string): Promise<StripeBankAccountView> => {
  const stripeRes = await learnlinkAPI.axios.get<StripeBankAccountView>(`${ENDPOINT}/connect/externalAccounts/${uid}`);
  return stripeRes.data;
};

export const getStripeCards = async(uid: string): Promise<StripeCardView> => {
  const stripeRes = await learnlinkAPI.axios.get<StripeCardView>(`${ENDPOINT}/customers/cards/${uid}`);
  return stripeRes.data;
};

export const deleteStripeExternalAccount = async(uid: string, accountID:string): Promise<void> => {
  const stripeRes = await learnlinkAPI.axios.delete(`${ENDPOINT}/deleteBankAccount/${uid}/${accountID}`);
  return stripeRes.data;
};

export const deleteStripeCard = async(uid: string, cardID: string): Promise<void> => {
  const stripeRes = await learnlinkAPI.axios.delete(`${ENDPOINT}/deleteCard/${uid}/${cardID}`);
  return stripeRes.data;
};

export const setAccountAsDefault = async(uid: string, bankAccountID: string): Promise<void> => {
  const stripeRes = await learnlinkAPI.axios.put(`${ENDPOINT}/setAccountAsDefault/${uid}`, { bankAccountID });

  return stripeRes.data;
};

export const setCardAsDefault = async(uid: string, cardID: string): Promise<void> => {
  const stripeRes = await learnlinkAPI.axios.put(`${ENDPOINT}/setCardAsDefault/${uid}`, { cardID });

  return stripeRes.data;
};

export const syncStripeAccounts = async(uid: string): Promise<void> => {
  await learnlinkAPI.axios.get(`${ENDPOINT}/syncExternalAccounts/${uid}`);
};
