

















































import { deleteStripeCard, setCardAsDefault } from "@/api/stripeService";
import { StripeCard } from "@/interfaces/Stripe";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "StripeCardTemplate",
  props: {
    card: {
      type: Object as PropType<StripeCard>,
      required: true,
    },
    defaultCard: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const notify = useNotifier();
    const defaultLoading = ref(false);
    const deleteLoading = ref(false);

    async function deleteCard() {
      try {
        deleteLoading.value = true;
        await deleteStripeCard(userModule.state.userId, props.card.id);
        notify({
          title: "Kortet ble slettet!",
          message: "",
          type: "success",
        });
        emit("deleted", props.card.id);
        deleteLoading.value = false;
      }
      catch (e) {
        deleteLoading.value = false;
        notify({
          title: "Oops",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
    }

    async function setAsDefault() {
      try {
        defaultLoading.value = true;
        await setCardAsDefault(userModule.state.userId, props.card.id);
        notify({
          title: "Informasjonen ble oppdatert",
          message: "Betalinger vil nå bli trukket fra dette kortet",
          type: "success",
        });
        emit("updated", props.card.id);
        defaultLoading.value = false;
      }
      catch (e) {
        defaultLoading.value = false;
        notify({
          title: "Oops",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
    }

    return {
      cardImage: computed(() => {
        if (props.card.brand === "Visa") return require("../../../assets/visa-logo.png");
        else if (props.card.brand === "MasterCard") return require("../../../assets/mastercard-logo.png");
        else if (props.card.brand === "American Express") return require("../../../assets/amex-logo.png");
        return "";
      }),
      defaultLoading,
      deleteCard,
      deleteLoading,
      setAsDefault,
    };
  },
});

