


































































































import EditProfile from "@/components/profile/EditProfile.vue";
import AboutMe from "@/components/settings/AboutMe.vue";
import DeleteAccount from "@/components/settings/DeleteAccount.vue";
import EmailAndPassword from "@/components/settings/EmailAndPassword.vue";
import ExistingCards from "@/components/settings/stripeSettings/ExistingCards.vue";
import ExternalAccounts from "@/components/settings/stripeSettings/ExternalAccounts.vue";
import LinkProviders from "@/components/settings/LinkProviders.vue";
import Notifications from "@/components/settings/notifications/Notifications.vue";
import UploadDocumentation from "@/components/settings/upload/UploadDocumentation.vue";

import { computed, defineComponent, ref } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import Account from "@/interfaces/Account";
import Profile from "@/interfaces/Profile";
import User from "@/interfaces/User";

export interface UserSettingsObject {
  account: Account,
  profile: Profile,
  stripeManagedAccount: {
    payouts_enabled: boolean;
    requirements: {
      currently_due: string[];
    },
  },
  user: User,
}

export default defineComponent({
  name: "Settings",
  components: {
    DeleteAccount,
    EditProfile,
    AboutMe,
    EmailAndPassword,
    ExistingCards,
    ExternalAccounts,
    LinkProviders,
    Notifications,
    UploadDocumentation,
  },
  setup: () => {
    // TODO: Add endpoint for stripeAccount? Or add to store.
    // TODO: Delete this if it isn't needed in child components.
    const settingsObject = ref<UserSettingsObject>({
      account: userModule.state.account,
      profile: userModule.state.profile,
      stripeManagedAccount: {
        payouts_enabled: true,
        requirements: {
          currently_due: [
            "individual.address.city",
          ],
        },
      },
      user: userModule.state.user,
    });

    return {
      isSeller: computed(() => userModule.state.user.seller),
      settingsObject,
    };
  },
});
