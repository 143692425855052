


































































import { userModule } from "@/store/modules/user";
import { updateAuthObject } from "@/api/authService";
import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  name: "EmailAndPassword",
  setup: () => {
    const vm = reactive({
      loading: false
    });

    const form = ref();
    const password = ref("");
    const repeatPassword = ref("");
    const updatedEmail = ref("");
    const notify = useNotifier();
    const validForm = ref(false);

    onMounted(() => {
      updatedEmail.value = userModule.state.account.email;
    });

    const formRules = {
      required: (v: string) => !!v || "Feltet er obligatorisk",
      email: {
        validChars: (v: string) => /.+@.+\..+/.test(v) || "Ugyldig e-postadresse",
      },
      password: {
        validLength: (v: string) => v.length >= 6 || "Passordet må være minst 6 tegn langt",
        match: () => password.value === repeatPassword.value || "Passordene må være like",
      },
    };

    async function updateEmailAndPassword() {
      try {
        await Promise.all([
          userModule.actions.updateUserAccount({ email: updatedEmail.value, uid: userModule.state.userId }),
          updateAuthObject({ email: updatedEmail.value, password: password.value, uid: userModule.state.userId }),
        ]);

        notify({
          title: "E-post og passord oppdatert",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Oops!",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
    }

    return {
      form,
      formRules,
      password,
      repeatPassword,
      updatedEmail,
      updateEmailAndPassword,
      validForm,
      vm
    };
  },
});

